.RichEditor-root {
  background: #fff;
  /* border: 1px solid #ddd; */
  font-family: inherit;
  font-size: 14px;
  padding: 15px;
  padding-top: 0;
}

.RichEditor-editor {
  /* border-top: 1px solid #ddd; */
  cursor: text;
  font-size: 20px;
  /* margin-top: 10px; */
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  /* padding: 15px; */
  padding: 0px 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  /* border-left: 5px solid #eee; */
  color: #666;
  font-family: inherit;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: inherit;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: inherit;
  user-select: none;
}

.BOLD {
  font-weight: 700;
}

.ITALIC {
  font-style: italic;
}

.UNDERLINE {
  text-decoration: underline !important;
}
